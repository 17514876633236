import React from 'react'
import './style.scss'
import Accueil from './accueil'
import 'typeface-playfair-display'
import 'typeface-open-sans'

export default () => {
  return <div>
    
      <Accueil />

    </div>
}